@import "../../styles/variables.scss";

.chartContainer {
    position: relative;
    margin: 32px 0 80px;
    @include mobileOnly {
        margin-bottom: 40px;
    }
}

.timelineContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.resetButton {
    background: white;
    outline: none;
    padding: 10px 22px 6px 22px;
    border-radius: 22px;
    font-family: $font-heading;
    font-size: rem(14);
    cursor: pointer;
    border: 1px solid black;
    text-transform: uppercase;

    position: absolute;
    top: 16px + 16px;
    right: 24px + 16px;

    transition: opacity 0.2s;

    &[data-visible="false"] {
        opacity: 0;
        pointer-events: none;
    }
}

.chartAndSidebar {
    position: relative;
}

.sidebarContainer {
    margin: 8px 0 32px 0;
}

.svgContainer {
    width: 100%;
    position: relative;
    &[data-sidebar="true"] {
        width: 66%;
    }
}

.svg {
    width: 100%;
    height: 600px + 16px + 58px;
    @include mobileOnly {
        height: 400px;
    }
}

.loadingFade {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background: rgba(white, 0.7);
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &[data-loading="false"] {
        opacity: 0;
    }
}

.dataGroup {
    &[data-zoom-dragging="true"] {
        pointer-events: none;
    }
}

.dataItem {
    stroke: white;
    fill: $color-pri;
    stroke-width: 1px;
    cursor: pointer;

    transition: opacity 0.2s;

    &[data-metro="true"] {
        fill: $color-metro;
    }
    &[data-regional="true"] {
        fill: $color-regional;
    }

    &[data-selected="true"] {
        stroke: black;
    }

    &[data-faded="true"] {
        opacity: 0.35;
    }

    @include mobileOnly {
        pointer-events: none;
    }
}

.selectionLine {
    stroke: $color-pri;
    stroke-width: 3;
    fill: none;

    &[data-metro="true"] {
        stroke: $color-metro;
    }
    &[data-regional="true"] {
        stroke: $color-regional;
    }
}

// -----------------

.chartFrame {
    fill: transparent;
    stroke: $color-grey6;
    cursor: zoom-in;
    &[data-zoom-dragging="true"] {
        cursor: crosshair;
    }
}

.axisNameLabel {
    composes: h4 from global;
    color: black;
}

.highlightSelectionArea {
    fill: transparent;
    stroke: none;
    &[data-disabled="true"] {
        pointer-events: none;
    }
}

.highlightHandle {
    &[data-dragging="true"],
    &[data-disabled="true"] {
        pointer-events: none;
    }

    &[data-rotate="true"] {
        transform: rotate(90deg);
    }
}

.highlightHandleRect {
    fill: $color-pri;
    cursor: col-resize;
    &[data-rotate="true"] {
        cursor: row-resize;
    }
}

.highlightHandleGrabber {
    pointer-events: none;
    line {
        fill: none;
        stroke: white;
        stroke-width: 1px;
    }
}

.highlightFill {
    fill: rgba($color-blue, 0.05);
    stroke: none;
}

.highlightStartLine,
.highlightEndLine {
    stroke-width: 1px;
    stroke: $color-blue;
}

.highlightStartLine {
    stroke-dasharray: 4 4;
}

.highlightLabelBackground {
    fill: $color-blue-light;
}

.highlightLabel {
    fill: $color-blue;
    text-anchor: middle;
    font-size: rem(14);
    font-weight: 500;
    font-family: $font-body;
    transform: translateY(5px);
}

// --------------

.hover {
    pointer-events: none;
}

.hoverLine {
    stroke: black;
    stroke-dasharray: 4 4;
}

.hoverLabelRect {
    fill: white;
    stroke: $color-grey6;
}

.hoverLabel {
    font-family: $font-heading;
    font-size: rem(18);
    letter-spacing: 0.4px;
    text-anchor: middle;
}

// --------------

.axisGridLine {
    stroke: $color-grey6;
}

.axisGridLabel {
    composes: body-small from global;
    fill: $color-grey3;
    font-family: $font-body;
}

.axisGridLabelX {
    @extend .axisGridLabel;
    transform: translateY(16px);
    text-anchor: middle;
}

.axisGridLabelY {
    @extend .axisGridLabel;
    transform: translate(-4px, 5px);
    text-anchor: end;
}

.zoomRect {
    stroke: black;
    stroke-dasharray: 4 4;
    fill: transparent;
    pointer-events: none;
}
