@import "../../styles/variables.scss";

.stories {
    position: relative;
    padding: 24px 0 48px 0;
    @include mobileOnly {
        display: none;
    }
}

.storiesHeading {
    margin: 0;
    font-weight: 500;
}

.storyItemContainer {
    display: flex;
    margin-top: 24px;
}

.storyItem {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    background: none;
    border: none;
    outline: none;
    background: white;
    cursor: pointer;
    border-radius: 10px;
    padding: 24px;
    box-shadow: $shadow-card;

    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-right: 32px;
    }

    transition: box-shadow 0.3s, transform 0.1s;

    &:hover {
        box-shadow: $shadow-deep;
        transform: scale(1.01);
    }

    &[data-selected="true"] {
        background: $color-pri;
        color: white;

        .storyTitle {
            color: white;
        }

        .storyBadge {
            background: none;
            border: 2px solid white;
            color: white;
        }
    }
}

.storyTitle {
    composes: h2 from global;
    text-align: left;
    transition: color 0.2s;
}

.storyDescription {
    composes: body-small from global;
    text-align: left;
    margin: 8px 0 0;
}

.storyBadge {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: $color-grey7;
    color: $color-blue;

    position: absolute;
    top: 16px;
    right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 18px;
        height: 18px;
    }
}
