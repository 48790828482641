@import "../../styles/variables.scss";

.chartLegend {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 20px 0;
}

.sizeSelection {
    p {
        margin: 0;
        margin-bottom: 2px;
        margin-right: 6px;
    }

    > div {
        display: flex;
    }

    @include mobileOnly {
        width: 250px;
    }
    @include tinyOnly {
        width: 100%;
        margin-bottom: 20px;
    }
}

.selectContainer {
    width: 168px;
    margin: -12px 16px 0 4px;
}

.bolder {
    font-weight: 500;
}

// ----
.sizeScale {
    display: flex;
    margin-top: 10px;
}

.sizeScaleItem {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-right: 12px;
    }
}

.sizeScaleDot {
    background: $color-grey6;
    border-radius: 50%;
    margin-right: 4px;
}

.sizeScaleLabel {
    composes: body-small from global;
}

// -----

.colorControl {
    p {
        margin: 0;
    }
    @include mobileOnly {
        width: 240px;
    }

    @include tinyOnly {
        width: 100%;
        margin-bottom: 20px;
    }
}

.colorCheckbox {
    border: 1px solid black;
    outline: none;
    background: none;
    cursor: pointer;
    margin: 0 16px 0 8px;

    width: 16px;
    height: 16px;
    position: relative;

    svg {
        width: 10px;
        height: 10px;
        position: relative;
        left: calc(-16px + 50%);
        transform: translatex(100%);
    }
}

.colorLegend {
    display: flex;
    margin: 10px 0 20px 40px;
}

.colorScaleItem {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-right: 12px;
    }

    &[data-type="metro"] {
        .colorScaleDot {
            background: $color-metro;
        }
    }
    &[data-type="regional"] {
        .colorScaleDot {
            background: $color-regional;
        }
    }
}

.colorScaleDot {
    width: 16px;
    height: 16px;
    background: $color-pri;
    border-radius: 50%;
    margin-right: 4px;
}

.scaleLabel {
    composes: body-small from global;
}

.dropdownIndicator {
    width: 12px;
}

.lineControl {
    p {
        margin: 0;
    }
    @include mobileOnly {
        width: 210px;
    }

    @include tinyOnly {
        width: 100%;
    }
}

.lineScaleItem {
    display: flex;

    svg {
        margin: 0 10px;
    }
}
