@import "../../styles/variables.scss";

.dataDictionary {
    margin-top: 48px;
    h2 {
        color: black;
    }
}

.items {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.item {
    padding: 16px 24px;
    border-radius: 10px;
    border: 1px solid $color-grey6;
    position: relative;
    box-sizing: border-box;
    width: calc(50% - 16px);
    margin-bottom: 16px;

    @include mobileOnly {
        width: 100%;
    }
}

.toggle {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;

    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    color: black;

    &[data-open="true"] {
        svg {
            transform: rotate(180deg);
        }
    }

    svg {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 16px;
        transition: transform 0.3s;
    }
}

.title {
    composes: h3 from global;
    margin: 0;
}

.subtitle {
    composes: body-small from global;
    color: $color-grey3;
}

.shortContent {
    margin: 0;
    padding-top: 1rem;
    composes: bdoy from global;
    line-height: 1.5;
}

.longContent {
    composes: body-small from global;
    margin: 0;
    padding-top: 1rem;
    color: $color-grey4;
}

.collapseAccordion {
    overflow: hidden;
}
