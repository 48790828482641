@import "../../styles/variables.scss";

.datasetSelection {
    display: flex;
    flex-direction: column;
}

.sentenceContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include mobileOnly {
        justify-content: left;
    }
}

.selectAndLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    @include mobileOnly {
        justify-content: left;
        width: 100%;
    }
}

.sentenceLabel {
    composes: body from global;
}

.selectContainer {
    display: inline;

    &[data-name="year"] {
        width: 110px;
    }
    &[data-name="measure"] {
        width: 296px;
    }
    &[data-name="comparison"] {
        width: 200px;
    }
    &[data-name="location"] {
        width: 400px;
    }

    @include mobileOnly {
        flex-basis: auto;
        flex: 1;
    }

    margin: 0 10px;

    :global(.dataset-select__menu-list) {
        // firefox
        scrollbar-color: $color-grey5 transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 6px;
            height: 0px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: $color-grey5;
            border-radius: 3px;
        }
    }
}

.dropdownOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectedOptionCheck {
    width: 12px;
    padding-right: 4px;
    color: $color-blue;
}

.dropdownIndicator {
    width: 12px;
    margin-right: 12px;
}

.menuContainer {
    z-index: 2;
    position: absolute;
    width: 100%;
    box-shadow: $shadow-card;
    border: 1px solid red;
}

.blanket {
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
}
