@import "../../styles/variables.scss";

.timeline {
    width: 100%;
    display: flex;
    align-items: center;
    @include mobileOnly {
        width: calc(100% - 24px);
    }
}

.playButton {
    outline: none;
    border: none;
    cursor: pointer;
    width: 58px;
    height: 58px;
    min-width: 58px;
    min-height: 58px;
    margin-right: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-blue-light;
    color: $color-blue;
    box-shadow: $shadow-card;

    transition: background 0.2s, color 0.2s,
        transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    transform: scale(0.8);
    svg {
        width: 16px;
    }

    &[data-playing="false"] {
        background: $color-blue;
        color: white;
        svg {
            transform: translateX(2px);
        }
        transform: scale(1);

        &:hover {
            background: $color-blue-light;
            color: $color-blue;
        }
    }

    @include mobileOnly {
        margin-right: 15px;
    }
}

.timelineProgress {
    flex-grow: 1;
    background: white;
    height: 44px;
    box-shadow: $shadow-card;
    border-radius: 22px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.timelineYearContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    position: relative;

    &:not(:last-child) {
        flex-grow: 1;
        flex-basis: 0;
    }
}

.timelineYearLabel {
    width: 80px;
    height: 100%;
    background: transparent;
    outline: none;
    cursor: pointer;
    color: $color-grey5;
    border: none;
    text-align: center;
    border-radius: 22px;
    line-height: 44px;
    font-family: $font-heading;
    font-size: rem(16);
    z-index: 3;
    &[data-current="true"] {
        background: $color-pri;
        color: white;
        font-weight: $w-h-bold;

        box-shadow: $shadow-deep;
        transform: scale(1.1);
    }

    transition: background 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;

    @include mobileOnly {
        width: 70px;
    }
}

.timeYearFillContainer {
    flex-grow: 1;
    height: 100%;
}

.timelineYearFill {
    position: absolute;
    left: 80px - 22px;
    height: 100%;
    background: $color-blue-light;
    z-index: 2;
    border-radius: 0 22px 22px 0;

    transition: opacity 0.3s;
    opacity: 1;
    &[data-current="false"] {
        opacity: 0;
    }
}
