@import "../../styles/variables.scss";

.sidebar {
    position: absolute;
    top: 14px;
    bottom: 64px;
    right: 0;
    width: calc(33% - 8px);
    background: white;
    border-radius: 10px;
    overflow: hidden;

    transition: transform 0.3s, opacity 0.3s;
    transform: translateX(0%);
    opacity: 1;

    box-shadow: $shadow-card;

    &[data-open="false"] {
        transform: translateX(100%);
        opacity: 0;
    }
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0;
    color: black;

    svg {
        width: 16px;
        height: 16px;
    }
}

// ----------------

.mapContainer {
    width: 100%;
    height: 180px;
    background: $color-grey7;

    :global(.leaflet-container) {
        height: 100%;
    }

    :global(.leaflet-marker-icon) {
        display: none;
    }
}

.sidebarContent {
    overflow-y: scroll;
    flex-grow: 1;
    padding: 24px;

    scrollbar-color: $color-grey5 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: $color-grey5;
        border-radius: 3px;
    }
}

.missingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.8);
    cursor: not-allowed;
    transition: opacity 0.2s;
    backdrop-filter: blur(3px);
    &[data-visible="false"] {
        opacity: 0;
        display: none;
        pointer-events: none;
    }
}

.missingHeading {
    composes: h2 from global;
    margin: 0;
    color: black !important;
    max-width: 60%;
    text-align: center;
}

// ----------------

.singleTitleArea {
    display: flex;
    flex-direction: row;
}

.singleHeadings {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.locationHeading {
    composes: h2 from global;
    margin: 0;
    color: black !important;
}

.locationSizeLabel {
    composes: body-small from global;
    margin: 0;
}

.singleMinimap {
    svg {
        width: 100px;
        height: 78px;
        color: $color-blue-light;
    }
}

.singleCharts {
    margin-top: 24px;
}

.singleChartContainer {
    margin-bottom: 16px;
}

.singleChartHeading {
    composes: h4 from global;
    margin: 0;
}

// --------------

.chartContainer {
}

.chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    width: 100%;
    margin: 8px 0;
}

.chartBar {
    background: $color-blue-light;
    width: 10px;
    border-radius: 5px;
    height: 100%;

    &[data-metro="true"] {
        background: $color-metro;
    }
    &[data-regional="true"] {
        background: $color-regional;
    }
    &[data-filled="true"] {
        background: $color-pri;
    }
}

.chartLabels {
    width: 100%;
    height: 28px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chartLabel {
    font-size: rem(12);
    font-family: $font-body;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 26px;
    height: 26px;

    &[data-value="true"] {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 14px;
        background: $color-grey6;
        padding: 0 12px;
        transform: translateX(-50%);
    }
}

// ---------------

.multiSelection {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.multiContentHeading {
    composes: h2 from global;
    color: black !important;
    margin-bottom: 16px;
}

.locationList {
}

.locationGeoSection {
    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.locationGeoHeading {
    composes: h4 from global;
    margin-bottom: 4px;

    &:before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 12px;
        background: $color-grey5;
    }

    &[data-type="metro"] {
        &:before {
            background: $color-metro;
        }
    }
    &[data-type="regional"] {
        &:before {
            background: $color-regional;
        }
    }
}

.locationGeoEntries {
    composes: body-small from global;
    white-space: pre-line;
    margin: 0;
}

.multiMap {
    background: $color-blue-light;
    min-height: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multiMapGeo {
    width: 226px;
    height: 118px;
    position: relative;
    margin-left: 32px;

    svg {
        width: 100%;
        height: 100%;
        color: white;
    }
}

.multiMapMarkerContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &[data-single="true"] {
        .multiMapMarker {
            width: 8px;
            height: 8px;
        }
    }
}

.multiMapMarker {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    background: $color-pri;
    &[data-metro="true"] {
        background: $color-metro;
    }
    &[data-regional="true"] {
        background: $color-regional;
    }
    transform: translate(-50%, -50%);
}
