@import "../../styles/variables.scss";

.locationDropdown {
    position: relative;
    width: 100%;
}

.filterHeading {
    composes: h6 from global;
    margin: 0;
    padding: 12px 0 4px 11px;
    color: $color-grey4;
}

.locationDropdownButton {
    width: 100%;
    border: none;
    background: white;
    outline: none;
    border-radius: 22px;
    padding: 13px 22px;
    padding-bottom: 9px;
    font-family: $font-heading;
    font-weight: $w-h-reg;
    font-size: rem(18);
    box-shadow: $shadow-card;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    transition: border-radius 0.2s;

    &[data-selected="true"] {
        border-radius: 22px 22px 0 0;
        z-index: 13;
    }
}

.searchSymbol {
    color: $color-blue;
}

.blanket {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
}

.dropdownIcon {
    width: 12px;
}

.dropdownContent {
    position: relative;
    height: 0;
    display: block;
    &[data-open="false"] {
        display: none;
    }
}

.selectContainer {
    position: relative;
    width: 100%;
    box-shadow: $shadow-card;
    background: white;

    :global(.location-select__menu-list) {
        // firefox
        scrollbar-color: $color-grey5 transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 6px;
            height: 0px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: $color-grey5;
            border-radius: 3px;
        }
    }
}

.locationOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectedOptionCheck {
    width: 12px;
    padding-right: 4px;
    color: $color-blue;
}
