@import "./variables.scss";

.gonski-body {
    margin: 0;
    font-family: $font-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.h1 {
    font-family: $font-heading;
    font-weight: $w-h-light;
    font-size: rem(64);
    line-height: 1.25;
    color: $color-pri;
    text-transform: uppercase;

    @include mobileOnly {
        font-size: rem(40);
    }
}

.h2 {
    font-family: $font-heading;
    font-weight: $w-h-reg;
    font-size: rem(24);
    line-height: 1.5;
    color: black;
}

.h3 {
    font-family: $font-body;
    font-weight: 500;
    font-size: rem(20);
    line-height: 1.8;

    @include mobileOnly {
        font-size: rem(16);
    }
}

.h4 {
    font-family: $font-heading;
    font-weight: normal;
    font-size: rem(18);
    line-height: 1.55;
}

.h5 {
    font-family: $font-body;
    font-weight: bold;
    font-size: rem(16);
    line-height: 1.3;
    text-transform: uppercase;
}

.h6 {
    font-family: $font-body;
    font-weight: bold;
    font-size: rem(12);
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 0.75px;
}

.body {
    font-family: $font-body;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.75;
    letter-spacing: 0.4px;
    font-weight: $w-b-light;
}

.body-small {
    font-family: $font-body;
    font-weight: normal;
    font-size: rem(14);
    line-height: 1.5;
}

.label {
    font-family: $font-body;
    font-weight: normal;
    font-size: rem(14);
    line-height: 3;
    text-transform: uppercase;
    color: $color-grey4;
}

.gonski-body h1 {
    @extend .h1;
}

.gonski-body h2 {
    @extend .h2;
}

.gonski-body h3 {
    @extend .h3;
}

.gonski-body h4 {
    @extend .h4;
}

.gonski-body h5 {
    @extend .h5;
}

.gonski-body h6 {
    @extend .h6;
}

.gonski-body p {
    @extend .body;
}

.gonski-body label {
    @extend .label;
}

.gonski-mobile-hide {
    @include mobileOnly {
        display: none;
    }
}

.gonski-tiny-hide {
    @include tinyOnly {
        display: none;
    }
}
