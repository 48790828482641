$color-grey1: #333;
$color-grey2: #4a4a4a;
$color-grey3: #6d6e71;
$color-grey4: #808080;
$color-grey5: #bcbec0;
$color-grey6: #f0f0f0;
$color-grey7: #f9f9f9;

// Primary colours
$color-orange: #e8b638;
$color-purple: #a885e0;
$color-blue: #0092c8;
$color-blue-light: #ddf0f9;

// Chart
$color-regional: $color-orange;
$color-metro: $color-purple;

$color-pri: $color-blue;
$color-text: $color-grey1;

$font-body: "Helvetica Neue", "Arial", -apple-system, BlinkMacSystemFont,
	"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
	"Droid Sans", sans-serif;
$font-heading: "sommet", $font-body;

$w-h-bold: 700;
$w-h-reg: 400;
$w-h-light: 300;

$w-b-light: 300;

$shadow-card: 0 0 12px 8px rgba(0, 0, 0, 0.03);
$shadow-deep: 0 0 16px 16px rgba(0, 0, 0, 0.06);

$width-mobile: 800px;
$width-tiny: 500px;

@function rem($sizepx) {
	@return ($sizepx / 16) + rem;
}

@mixin mobileOnly {
	@media (max-width: $width-mobile) {
		@content;
	}
}

@mixin tinyOnly {
	@media (max-width: $width-tiny) {
		@content;
	}
}
