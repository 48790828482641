@import "./styles//variables.scss";

.app {
    margin: 0;
    font-family: $font-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    position: relative;
}

.container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    h1 {
        margin-bottom: 0rem;
    }
    h3 {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }
}

.waveBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 611px;
    z-index: -1;

    background-image: url("./styles/img/bg-wave.svg");
    background-position: center center;
}

.dictionaryAnchor {
    width: 100%;
    color: $color-grey4;
    font-family: $font-body;
    font-size: 12px;
    letter-spacing: 0.34px;
    display: block;
    text-align: center;
    text-decoration: none;
    margin: 16px 0;

    &:hover {
        text-decoration: underline;
    }

    svg {
        width: 7px;
    }
}

.inlineIcon {
    height: 10px;
}

.footer {
    margin-top: 72px;
    background: $color-blue-light;
    padding: 48px 0;

    @include mobileOnly {
        padding: 24px 10px 10px;
    }
}

.footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: flex-start;
    svg {
        height: 80px;
        @include mobileOnly {
            height: 40px;
        }
    }

    @include tinyOnly {
        display: block;
    }
}

.footerNotes {
    font-family: $font-body;
    font-size: rem(12) !important;
    line-height: 1.33 !important;
    color: $color-grey3;
    max-width: 490px;
    text-align: right;
    margin-left: 32px;
    margin-right: 16px;

    a {
        margin-bottom: 10px;
        display: block;
    }

    @include mobileOnly {
        margin-top: 0;
    }
}

.smuCredit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none;

    margin-left: 32px;

    white-space: nowrap;

    span {
        font-family: $font-body;
        font-size: rem(12);
        color: $color-grey1;
        margin-bottom: 16px;
    }

    svg {
        height: 36px;
        margin-bottom: 4px;

        @include mobileOnly {
            height: 18px;
        }
    }
}
